<template>
  <div class="pt-24">
    <div class="
          container
          px-3
          mx-auto
          flex flex-wrap flex-col-reverse
          md:flex-row
          items-center
          mb-12
        ">
      <!--Left Col-->
      <div class="
            flex flex-col
            w-full
            md:w-2/5
            justify-center
            items-start
            text-center
            md:text-left
            mb-4
          ">
        <div class="bg-white rounded relative p-8">
          <div class="main-search-input-headline actu">
            <h2 class="actu_titre mb-4">
              {{ $t("home.searchLand") }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </h2>
          </div>
          <input @keypress.enter="search" :placeholder="$t('home.searchInput')" v-model="searchVal" type="text" name=""
            id="" />
          <button @click="search" class="w-full text-white p-6 mt-4 font-bold rounded bg-red-600" type="submit">{{
            $t("home.search") }}</button>
        </div>
      </div>
      <!--Right Col-->
      <div class="w-full md:w-3/5 py-6 text-center">
        <img class="w-full md:w-full z-50" src="banner.png" />
      </div>
    </div>
  </div>

  <div class="relative -mt-12 lg:-mt-24">
    <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
          <path
            d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
            opacity="0.100000001"></path>
          <path
            d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
            opacity="0.100000001"></path>
          <path
            d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
            id="Path-4" opacity="0.200000003"></path>
        </g>
        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
          <path
            d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z">
          </path>
        </g>
      </g>
    </svg>
  </div>

  <section class="bg-white border-b py-8" data-carousel="static" id="controls-carousel">
    <div class="container mx-auto flex flex-wrap pt-4 pb-12">
      <h2 class="
            w-full
            my-2
            text-5xl
            font-bold
            leading-tight
            text-center text-gray-800
          ">
        {{ $t("home.services") }}
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
        <div class="
              flex-1
              bg-white
              rounded-t rounded-b-none
              overflow-hidden
              shadow-2xl
              p-8
            ">
          <div class="cursor-pointer flex flex-wrap justify-center" @click="$router.push({ name: 'announces' })">
            <h1 class="w-full text-gray-600 text-2xl text-4xl text-bold px-6">
              LandsSearch
            </h1>
            <div class="icon-box-2 h-85">
              <i class="im im-icon-Mail-Search"></i>
            </div>
            <div class="w-full text-2xl text-gray-800 px-6">
              {{ $t("home.landsearch") }}
            </div>
            <p class="text-gray-800 text-base px-6 mb-5"></p>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
        <div class="
              flex-1
              bg-white
              rounded-t rounded-b-none
              overflow-hidden
              shadow-2xl
              p-8
            ">
          <div class="cursor-pointer flex flex-wrap justify-center" @click="$router.push({ name: 'auction' })">
            <h1 class="w-full text-gray-600 text-2xl text-4xl text-bold px-6">
              LandsAuction
            </h1>
            <div class="icon-box-2 h-85">
              <i class="im im-icon-Coin"></i>
            </div>
            <div class="w-full text-2xl text-gray-800 px-6">
              {{ $t("home.landauction") }}
            </div>
            <p class="text-gray-800 text-base px-6 mb-5"></p>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
        <div class="
              flex-1
              bg-white
              rounded-t rounded-b-none
              overflow-hidden
              shadow-2xl
              p-8
            ">
          <div class="cursor-pointer flex flex-wrap justify-center" @click="$router.push({ name: 'landsAdvisor' })">
            <h1 class="w-full text-gray-600 text-2xl text-4xl text-bold px-6">
              LandsAdvisor
            </h1>
            <div class="icon-box-2 h-85">
              <i class="fa fa-tripadvisor"></i>
            </div>
            <div class="w-full text-2xl text-gray-800 px-6">
              {{ $t("home.landadvisor") }}
            </div>
            <p class="text-gray-800 text-base px-6 mb-5"></p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-white border-b py-8">
    <div class="container mx-auto m-8">
      <h2 class="
            w-full
            my-4
            text-5xl
            font-bold
            leading-tight
            text-center text-gray-800
          ">
        {{ $t("home.lastAnn") }}
      </h2>
      <div class="w-full mb-12">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-wrap justify-center">
        <!-- Listing Item -->
        <div v-for="(post, index) in allPosts" :key="index" class="fw-carousel-item col-md-4">
          <a @click="oneClicked(post.id)" class="listing-item-container compact">
            <div class="listing-item">
              <img :src="baseUrl + '/' + post.image" alt="" />

              <div class="listing-item-content">
                <h3>{{ post.title }}</h3>
                <span>{{ post.title }}</span>
              </div>
              <!--span class="like-icon"></span-->
            </div>
          </a>
        </div>
        <!-- Listing Item / End -->
      </div>
    </div>
  </section>

  <section class="bg-white border-b py-8">
    <div class="container mx-auto m-8">
      <h2 class="
            w-full
            my-4
            text-5xl
            font-bold
            leading-tight
            text-center text-gray-800
          ">
        {{ $t("home.blog") }}
      </h2>
      <div class="w-full mb-12">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>

      <div class="row">
        <!-- Blog Post Item -->
        <div class="col-md-4">
          <a @click="$router.push({ name: 'BlogDetails1' })" class="blog-compact-item-container">
            <div class="blog-compact-item">
              <img src="images/beautiful-pineapple-plant-south-africa-during-daytime.jpg" alt="" />

              <div class="blog-compact-item-content">
                <ul class="blog-post-tags">
                  <li>Febuary 27, 2022</li>
                </ul>
                <h3>
                  Valoriser les terres agricoles pour mettre fin à la faim
                </h3>
                <p>
                  Il est un fait ou plutôt un paradoxe que l’on ne souligne pas
                  assez. Le continent africain possède 65 % des terres arables
                  disponibles sur la planète et, en même temps, dépense chaque
                  année plus de 50 milliards de dollars en importation de
                  denrées alimentaires
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- Blog post Item / End -->

        <!-- Blog Post Item -->
        <div class="col-md-4">
          <a @click="$router.push({ name: 'BlogDetails2' })" class="blog-compact-item-container">
            <div class="blog-compact-item">
              <img src="images/medium-shot-farmer-cornfield.jpg" alt="" />
              <div class="blog-compact-item-content">
                <ul class="blog-post-tags">
                  <li>Febuary 27, 2022</li>
                </ul>
                <h3>
                  Valorisez votre terre agricole au meilleur prix grâce à notre
                  mécanisme d'enchères.
                </h3>
                <p>
                  Avec 60% des terres arables non exploitées dans le monde,
                  l’Afrique est clairement une attraction pour toute sorte
                  d'appétit d’achats et de valorisation de la terre agricole
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- Blog post Item / End -->

        <!-- Blog Post Item -->
        <div class="col-md-4">
          <a @click="$router.push({ name: 'BlogDetails3' })" class="blog-compact-item-container">
            <div class="blog-compact-item">
              <img src="images/close-up-hands-holding-plants.jpg" alt="" />
              <div class="blog-compact-item-content">
                <ul class="blog-post-tags">
                  <li>Febuary 27, 2022</li>
                </ul>
                <h3>
                  Déterminez le profil cultural de votre terre grâce à
                  l'intelligence artificielle de LandsApp.
                </h3>
                <p>
                  En Afrique de l'Ouest, la main-d'œuvre dans le secteur
                  agricole est un domaine d'intérêt clé pour les économies
                  actuelles et futures
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- Blog post Item / End -->

        <!--div class="col-md-12 centered-content">
          <a
            @click="$router.push({ name: 'BlogDetails' })"
            class="button border margin-top-10"
            >Accéder à notre blog</a
          >
        </div-->
      </div>
    </div>
  </section>

  <!-- Change the colour #f8fafc to match the previous section colour -->
  <svg class="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
        <g class="wave" fill="#f8fafc">
          <path
            d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z">
          </path>
        </g>
        <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
          <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
            <path
              d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
              opacity="0.100000001"></path>
            <path
              d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
              opacity="0.100000001"></path>
            <path
              d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
              opacity="0.200000003"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <section class="container mx-auto text-center py-6 mb-12">
    <!--Here slide patners infos-->
  </section>
  <!--Footer-->
</template>

<script>
// @ is an alias to /src HomeView

import { mapActions } from "vuex";

//var position = 0;
var msg = "VOTRE TEXTE DEFILANT";
msg = "     " + msg;
//var longue = msg.length;
var fois = 70 / msg.length + 1;
let i;
for (i = 0; i <= fois; i++) msg += msg;
/*function textdefil() {
  document.form1.deftext.value = msg.substring(position, position + 70);
  position++;
  if (position == longue) position = 0;
  setTimeout("textdefil()", 100);
}
window.onload = textdefil;
*/
export default {
  name: "HomeView",
  components: {
    //ProductCard,
  },
  data() {
    return {
      input: "",
      allPosts: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      searchVal: "",
    };
  },
  watch: {
    input: function (val) {
      console.log(val, "la valeur change");
      document.querySelector(".gradient").style.background =
        "linear-gradient(90deg, #126002 0%, #06bd34 100%)";
      var gradient = window.document.getElementsByClassName("gradient");
      console.log(gradient);
    },
    posts: function (val) {
      this.allPosts = val.reverse();
      console.log(val, "la valeur change");
      this.allPosts = this.allPosts.slice(0, 5);
    },
  },
  // activated() {
  //   var gradient = document.getElementsByClassName("gradient")[0];
  //   console.log(gradient);
  // },
  async mounted() {
    window.scrollTo(0, 0);
    await this.getAllPost();
    //location.reload()
  },
  computed: {
    posts() {
      console.log("la valeur change");
      // const size = 4;
      return this.$store.state.posts.all;
    },
  },
  methods: {
    ...mapActions({
      whoami: "auth/whoami",
      getAllPost: "posts/getAll",
    }),
    test(e) {
      this.input = e;
      console.log("the event is heart", e);
    },
    oneClicked(id) {
      this.$router.push({ name: "postDetails", params: { id } });
    },
    search() {
      localStorage.setItem("land.search", this.searchVal);
      this.$router.push({ name: "announces" });
    },
  },
};


</script>
<style>
.actu_titre {
  display: inline-block;
  padding: 0 25px;
  font-size: 25px;
  white-space: nowrap;
  animation: scrollTxt 20s linear infinite;
}

@keyframes scrollTxt {
  0% {
    transform: translate(20%, 0);
  }

  100% {
    transform: translate(-15%, 0);
  }
}
</style>

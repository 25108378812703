import {
  createStore,
  mapGetters as vuexMapGetters,
  mapActions as vuexMapActions,
} from "vuex";
import auth from "./modules/auth";
import utilities from "./modules/utilities";
import posts from "./modules/posts";
import { mapFields as vuexMapFields } from "vuex-map-fields";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    utilities,
    posts,
  },
});

function mapGetters(module, getters) {
  return vuexMapGetters(`entities/${module}`, getters);
}

function mapActions(module, actions) {
  return vuexMapActions(`entities/${module}`, actions);
}

function mapFields(module, fields) {
  return vuexMapFields(`entities/${module}`, fields);
}

export { mapFields, mapGetters, mapActions };

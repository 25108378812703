export default {
  USER_ID(state, data) {
    state.userId = data;
  },
  USER_INFO(state, data) {
    state.userInfo = data;
  },
  USER_PREFERENCES(state, data) {
    state.userPreferences = data;
  },
};

<template>
  <div id="nav">
    <nav id="header" style="
          background: #126002;
          width: 100%;
          position: fixed;
          margin-left: -30px;
        " class="top-0 text-black bg-white">
      <div style="" class="
            w-full
            flex flex-wrap
            items-center
            justify-between
            mt-0
            py-2
          ">
        <div class="pl-4 flex items-center">
          <a class="
                toggleColour
                text-black
                no-underline
                hover:no-underline
                font-bold
                text-2xl
                lg:text-4xl
                mr-16
              " href="#">
            <img style="width: 200px" src="images/White-logo-nobackground.png" alt="LandsApp" srcset="" />
          </a>
        </div>
        <div class="block lg:hidden pr-4">
          <button id="nav-toggle" class="
                flex
                items-center
                p-1
                text-pink-800
                hover:text-gray-900
                focus:outline-none focus:shadow-outline
                transform
                transition
                hover:scale-105
                duration-300
                ease-in-out
              ">
            <svg class="fill-white h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div class="
              w-full
              lg:!flex
              items-start
              lg:flex-col-menu lg:flex-grow lg:items-center lg:w-auto
              mt-2
              justify-between
              lg:mt-0 lg:bg-transparent
              p-4
              lg:p-0
              z-20
              hidden
            " id="nav-content">
          <ul class="flex w-1/2 mr-16 items-start lg:flex-row lg:justify-between flex-col lg:items-center">
            <li>
              <router-link class="font-bold lg:mr-6 text-white md:hover:underline underline-offset-8 rounded-full p-4"
                style="font-size: 20px" to="/">{{ $t("home.nav.home") }}</router-link>
            </li>

            <li>
              <router-link class="font-bold lg:mr-6 text-white md:hover:underline underline-offset-8 rounded-full p-4"
                style="font-size: 20px" to="/announces">{{ $t("home.nav.Announces") }}</router-link>
            </li>

            <li v-if="authenticated">
              <router-link class="font-bold lg:mr-6 text-white md:hover:underline underline-offset-8 rounded-full p-4"
                style="font-size: 20px" to="/favorites">{{ $t("home.nav.favorites") }}</router-link>
            </li>

            <li>
              <router-link class="font-bold lg:mr-6 text-white md:hover:underline underline-offset-8 rounded-full p-4"
                style="font-size: 20px" to="/auction">{{ $t("home.nav.Auction") }}</router-link>
            </li>

            <li>
              <router-link class="lg:mr-6 text-white md:hover:underline underline-offset-8 rounded-full p-4 font-bold"
                style="font-size: 20px" to="/lands-advisor">{{ $t("home.nav.LandsAdvisor") }}</router-link>
            </li>
          </ul>
          <div class="flex mr-8 lg:ml-16 flex-col lg:flex-row lg:h-16">

            <div v-if="authenticated" class="cursor-pointer text-white font-bold py-4 relative" @click="cust = !cust"> hi
              {{ firstname }} !

              <div @click="cust = false"
                class="absolute flex flex-col rounded items-start  mb-2 lg:mb-0 justify-start bg-white top-11"
                v-if="cust">
                <div class="text-black hover:bg-gray-200 p-4 w-full flex justify-start items-start"
                  @click="openSet;
                  $router.push({ name: 'setting' });
                                                                                                                                                      ">
                  {{ $t("home.nav.Account") }}
                </div>
                <div class="text-black hover:bg-gray-200 p-4 w-full" @click=" signOut ">
                  {{ $t("home.nav.Sign-out") }}
                </div>
              </div>
            </div>

            <a v-else @click=" signinMet " class="button border border-white with-icon font-bold  mb-2 lg:mb-0"
              style="border-color: white;"><i class="im im-icon-Male"></i>
              {{ $t("home.nav.Sign-in") }}</a>
            <a @click=" createNew " class="button border border-white  mb-2 lg:mb-0 with-icon font-bold md:ml-6"
              style="border-color: white;">
              {{ $t("home.nav.Add") }} <i class="sl sl-icon-plus"></i></a>

            <div href="" class="mb-4 with-icon font-bold flex ml-10 justify-between items-center">

              <VSelect :value=" { value: $i18n.locale, libelle: $i18n.locale.toUpperCase() } " @choose=" langSelec "
                :data=" [{ value: 'fr', libelle: 'FR' }, { value: 'en', libelle: 'EN' }] " />
            </div>
          </div>
        </div>
      </div>
      <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  </div>
  <!-- Sign In Popup -->

  <div class="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true"
    v-show=" signin " style="z-index: 1000">
    <div class="
          flex flex-wrap
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        ">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->

      <div class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            mt-36
            sm:align-middle
            lg:w-1/2
          ">
        <div class="bg-white px-4 pb-4 sm:px-12 sm:pb-4">
          <div id="" class="bg-white">
            <div class="small-dialog-header flex justify-between">
              <h3>{{ $t("home.sign.Sign") }}</h3>
              <div @click=" signin = false " class="cursor-pointer">x</div>
            </div>

            <!--Tabs -->
            <div class="sign-in-form style-1 px-4">
              <ul class="tabs-nav cursor-pointer">
                <li class="">
                  <a :class="
                    {
                      'active': signInMod,
                                                                                                                                                }
                  " @click="
  signInMod = true;
  signUpMod = false;
">{{ $t("home.nav.Sign-in") }}</a>
                </li>
                <li>
                  <a :class="
                    {
                      'active': signUpMod,
                                                                                                                                                }
                  " @click="
  signInMod = false;
  signUpMod = true;
">{{ $t("home.nav.Sign-up") }}</a>
                </li>
              </ul>
              <br />
              <br />

              <div class="alt pb-10">
                <!-- Login -->
                <div class="" v-if=" signInMod " style="">
                  <form method="post" class="login" autocomplete="off">
                    <div class="text-red-500" v-if=" errorLogin === 'Username or password failed' ">
                      {{ $t("home.sign.form1.error") }}
                    </div>
                    <div class="md:flex justify-between">
                      <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                        <label for="username">
                          {{ $t("home.sign.form1.username") }}
                          <i class="im im-icon-Male"></i>
                          <input v-model=" username " type="text" class="input-text" name="username" id="username" />
                        </label>
                      </p>

                      <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                        <label for="password">{{ $t("home.sign.form1.password") }}
                          <i class="im im-icon-Lock-2"></i>
                          <input v-model=" password " class="input-text" type="password" name="password" id="password" />
                        </label>
                      </p>
                    </div>

                    <p class="form-row form-row-wide">
                      <span class="lost_password cursor-pointer">
                        <a @click="
                          signin = false;
                          recorver = true;
                        ">{{ $t("home.sign.form1.passwordlost") }}</a>
                      </span>
                    </p>

                    <p v-if=" errorLogin.length != 0 " class="form-row form-row-wide">
                      <span class="">
                        <div class="text-red-400" href="#">
                          {{ errorLogin }}
                        </div>
                      </span>
                    </p>

                    <div class="form-row">
                      <div class="flex justify-between">
                        <div @click=" signin = false " class="
                              border-2
                              rounded-full
                              flex flex-wrap
                              justify-center
                              items-center
                              cursor-pointer
                              border-red-500 border-solid
                              text-red-500
                              font-semibold
                              w-1/2
                              mr-4
                              margin-top-5
                            ">
                          {{ $t("cancel") }}
                        </div>
                        <input @click.prevent=" loginMet " type="submit" class="button border w-1/2 mr-4 margin-top-5"
                          :value=" $t('save') " />
                      </div>
                      <div class="checkboxes margin-top-10">
                        <input id="remember-me" type="checkbox" name="check" />
                        <label for="remember-me">{{
                          $t("home.sign.form1.remember")
                          }}</label>
                      </div>
                    </div>
                  </form>
                </div>

                <!-- Register -->
                <div class="" v-if=" signUpMod ">
                  <form method="post" class="register" autocomplete="off">
                    <div class="md:flex justify-between">
                      <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                        <label for="username2">{{ $t("home.sign.form2.firstname") }} * :
                          <i class="im im-icon-Male"></i>
                          <input type="text" class="input-text" name="firstname" id="firstname" v-model=" firstname " />
                        </label>
                      </p>

                      <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                        <label for="password">{{ $t("home.sign.form2.lastname") }} * :
                          <i class="im im-icon-Male"></i>
                          <input v-model=" lastname " class="input-text" type="text" name="lastname" id="lastname" />
                        </label>
                      </p>
                    </div>

                    <div class="md:flex justify-between">
                      <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                        <label for="email2">{{ $t("home.sign.form2.email") }} * :
                          <i class="im im-icon-Mail"></i>
                          <input type="text" class="input-text" v-model=" email " name="email" id="email2" />
                        </label>
                      </p>

                      <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                        <label for="password">{{ $t("home.sign.form2.username") }} * :
                          <i class="im im-icon-Male"></i>
                          <input v-model=" username " class="input-text" type="text" name="username" id="username" />
                        </label>
                      </p>
                    </div>

                    <div class="md:flex justify-between">
                      <p class="form-row form-row-wide md:mr-4 md:w-1/2">
                        <label for="password1">{{ $t("home.sign.form1.password") }} * :
                          <i class="im im-icon-Lock-2"></i>
                          <input v-model=" password " class="input-text" type="password" name="password1"
                            @focus=" passwordFocus = true " id="password1" />
                        </label>
                        <small v-if=" passwordFocus " id="passwordHelp" class="form-text text-muted">{{
                          $t("home.sign.form2.passwordmust") }}
                          <div :class="
                            has_minimum_lenth
                              ? 'text-green-500'
                              : 'text-red-400'
                          ">
                            8 caractères
                          </div>
                          <div :class="
                            has_lowercase ? 'text-green-500' : 'text-red-400'
                          ">
                            {{ $t("home.sign.form2.downcase") }}
                          </div>
                          <div :class="
                            has_uppercase ? 'text-green-500' : 'text-red-400'
                          "></div>
                          <div :class="
                            has_number ? 'text-green-500' : 'text-red-400'
                          ">
                            {{ $t("home.sign.form2.number") }}
                          </div>
                          et
                          <div :class="
                            has_special ? 'text-green-500' : 'text-red-400'
                          ">
                            {{ $t("home.sign.form2.specialChar") }}
                          </div>
                        </small>
                      </p>

                      <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                        <label for="password2">
                          {{ $t("home.sign.form2.passwordRep") }} * :
                          <i class="im im-icon-Lock-2"></i>
                          <input v-model=" passwordRep " class="input-text" type="password" name="password"
                            @focus=" passwordRepFocus = true " id="password" />
                        </label>
                        <small v-if=" passwordRepFocus " id="passwordHelp" class="form-text text-muted"
                          :class=" repeted ? 'text-green-500' : 'text-red-400' ">{{ $t("home.sign.form2.passwordSame") }}
                        </small>
                      </p>
                    </div>

                    <div class="md:flex justify-between">
                      <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                        <label for="password">{{ $t("home.sign.form2.phone") }} * :
                          <i class="im im-icon-Phone-3"></i>
                          <input v-model=" phone " class="input-text" type="tel" placeholder="ex: (229)97000000"
                            name="phone" id="phone" />
                        </label>
                      </p>
                      <p class="form-row form-row-wide md:ml-4 md:w-1/2">
                        <label for="password">Profession (Optionnel) :
                          <i class="im im-icon-Lock-2"></i>
                          <select class="input-text mt-3" style="position: relative" v-model=" profession "
                            data-placeholder="Opening Time">
                            <option label="choisissez votre profession"></option>
                            <option v-for="(       profession, index       ) in        professions       " :key=" index ">
                              {{ profession }}
                            </option>
                          </select>
                        </label>
                      </p>
                    </div>
                    <div class="flex justify-between" @click=" signin = false ">
                      <div class="
                            border-2
                            rounded-full
                            flex flex-wrap
                            justify-center
                            items-center
                            cursor-pointer
                            border-red-500
                            text-red-500
                            font-semibold
                            w-1/2
                            mr-4
                            margin-top-5
                            border-solid
                          " style="border">
                        {{ $t("cancel") }}
                      </div>
                      <input type="submit" class="button border fw margin-top-10 w-1/2 mb-3" name="register"
                        :value=" $t('save') " @click.prevent=" registerMet " />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-view style="background: white" />

  <footer class="bg-white">
    <div class="container mx-auto px-8">
      <div class="w-full flex flex-col md:flex-row py-6">
        <div class="flex-1 mb-6 text-black">
          <div>
            <img class="footer-logo h-44" src="images/Color-logo-with-background.png" alt="" />
            <br /><br />
            <h1 class="text-3xl -mt-6">{{ $t("home.footer.punch") }}</h1>
            <ul style="" class="social-icons ml-44 margin-top-20">
              <li>
                <a class="facebook" href="https://www.facebook.com/profile.php?id=100086504905280"><i
                    class="icon-facebook"></i></a>
              </li>
              <li>
                <a class="twitter" href="https://twitter.com/LandsAppAfrica?t=JDKl2mYpJeZrX8n6FPz8Pw&s=08"><i
                    class="icon-twitter"></i></a>
              </li>
              <li>
                <a class="linkedin" href="https://www.linkedin.com/company/landsappafrica/"><i
                    class="icon-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex-1 ml-24 text-start">
          <p class="uppercase  text-gray-500 md:mb-6">{{ $t("home.footer.links") }}</p>
          <ul class="list-reset flex flex-col intems-start text-start mb-6">
            <li v-if=" !authenticated " class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a class="cursor-pointer no-underline
                    hover:underline
                    text-gray-800
                    hover:text-pink-500" @click=" signinMet ">{{
                $t("home.nav.Sign-in")
                }}</a>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a class="cursor-pointer no-underline
                    hover:underline
                    text-gray-800
                    hover:text-pink-500" @click=" accountSet ">{{ $t("home.nav.Account") }}</a>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a class="cursor-pointer no-underline
                    hover:underline
                    text-gray-800
                    hover:text-pink-500" @click=" createNew ">{{
                $t("home.nav.Add")
                }}</a>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a class="cursor-pointer no-underline
                    hover:underline
                    text-gray-800
                    hover:text-pink-500" href="blog/details">{{
                $t("home.footer.blog")
                }}</a>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a class="cursor-pointer no-underline
                    hover:underline
                    text-gray-800
                    hover:text-pink-500" @click=" $router.push({ name: 'about' }) ">{{ $t("home.footer.team") }}</a>
            </li>

            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a class="cursor-pointer no-underline
                    hover:underline
                    text-gray-800
                    hover:text-pink-500" href="mailto:contact@landsapp.com">Contact</a>
            </li>
          </ul>
        </div>
        <div class="flex-1 text-start">
          <div v-if=" errors.length !== 0 " class="notification bg-red-300 large margin-bottom-55">
            <h4>Oups! Quelque chose ne va pas 🙂</h4>
            <p v-for="(       error, index       ) in        errors       " :key=" index ">{{ error }}</p>
          </div>
          <div class="col-md-12">
            <h4 class="text-3xl mb-4 font-medium">
              {{ $t("announce.newsLetter") }}
            </h4>
            <input min="0" placeholder="Email" v-model=" emailNew " type="email" />
            <input min="0" :placeholder=" $t('announce.newsLetterName') " v-model=" name " type="text" />
            <a style="color: white" @click=" submit " class="button preview flex">{{ $t("home.nav.Sign-up") }}</a>
          </div>
        </div>


        <div v-if=" success " style="z-index: 999999999"
          class="fixed bg-green-600 w-192 h-32 rounded p-5 text-white flex top-4 right-4">
          {{ $t("home.toast.checkEmail") }}
          <div @click=" success = false ">x</div>
        </div>

        <div v-if=" deleted " style="z-index: 999999999"
          class="fixed bg-green-600 w-192 h-32 rounded p-5 text-white flex justify-between top-4 right-4">
          {{ $t("home.toast.deletedAnnounce") }}
          <div class="cursor-pointer" @click=" deleted = false ">x</div>
        </div>

        <div v-if=" errorPass " style="z-index: 999999999"
          class="fixed bg-red-500 w-192 h-32 rounded p-5 text-white flex justify-between top-4 right-4">
          {{ $t("home.toast.wrongId") }}
          <div class="cursor-pointer" @click=" deleted = false ">x</div>
        </div>


      </div>
    </div>
    <span>C/1046 FIYEGNON Cotonou, Phone: <span>(+229) 66-17-08-50 </span>, <span>
        <a class="no-underline
                    hover:underline
                    text-gray-800
                    hover:text-pink-500" href="mailto:contact@landsapp.com">
          contact@landsapp.com</a> </span></span>
  </footer>
</template>

<script>
import * as yup from "yup";
import { mapActions } from "vuex";
import VSelect from "./components/VSelect.vue";

let schemaTwo = yup.object().shape({
  email: yup.string().required("Le champ email est un champ requis"),
  name: yup.string().required("Le champ nom et prénom est un champ requis"),
});
export default {
  components: {
    VSelect
  },
  data() {
    return {
      signin: false,
      username: "",
      password: "",
      passwordRep: "",
      firstname: "",
      lastname: "",
      email: "",
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      phone: "",
      role: 0,
      key: 0,
      has_minimum_lenth: false,
      has_number: false,
      has_lowercsae: false,
      has_uppercase: false,
      has_special: false,
      passwordFocus: false,
      passwordRepFocus: false,
      errorPass: false,
      repeted: false,
      professions: [
        "Acheteur",
        "Administrateur de base de données",
        "Agent de sûreté aéroportuaire",
        "Agent de transit",
        "Agent d'entretien",
        "Agent funéraire",
        "Agent immobilier",
        "Agent de police",
        "Agent de presse",
        "Agent de sécurité",
        "Agent de surveillance de la voie publique (ASVP)",
        "Agronome",
        "Ambulancier",
        "Analyste financier",
        "Analyste programmeur",
        "Analyste Web",
        "Architecte",
        "Architecte paysagiste",
        "Architecte en système d'information",
        "Archiviste",
        "Assitant administratif et financier",
        "Assistant blibliothécaire",
        "Assistant d'éducation",
        "Assistant dentaire",
        "Assistant marketing",
        "Assistant paie et administration",
        "Assistant RH",
        "Assureur",
        "Audioprothésiste",
        "Auditeur financier",
        "Auxiliaire de puériculture",
        "Auxiliaire de vie sociale",
        "Avocat",
        "Bibliothécaire",
        "Biologiste",
        "Chargé de communication",
        "Chargé de recrutement",
        "Charpentier",
        "Chauffeur de taxi",
        "Chefs de chantier",
        "Chef de projet",
        "Chef de projet marketing",
        "Chef d'établissement",
        "Chiropracteur",
        "Chirurgien orthopédiste",
        "Coiffeur",
        "Commis de cuisine",
        "Comptable",
        "Concepteur de jeux vidéos",
        "Concepteur-rédacteur",
        "Conducteur de grue",
        "Conducteur de train",
        "Conseiller",
        "Conseiller d'orientation",
        "Conseiller en insertion professionnelle",
        "Consultant bien-être",
        "Consultant en informatique",
        "Contrôleur (trains)",
        "Contrôleur aérien",
        "Contrôleur de gestion",
        "Contrôleur financier",
        "Courtier en assurance",
        "Cuisinier",
        "Décorateur d'intérieur",
        "Dentiste",
        "Designer industriel",
        "Développeur informatique",
        "Diacre",
        "Diététicien",
        "Directeur artistique",
        "Directeur des ventes",
        "Directeur financier",
        "Directeur RH",
        "Economiste",
        "Educateur de jeunes",
        "Eleveur",
        "Employé de banque",
        "Enseignant",
        "Ergothérapeute",
        "Esthéticienne",
        "Fleuriste",
        "Gardien",
        "Géomètre",
        "Gestionnaire immobilier",
        "Hôtesse de l'air",
        "Huissier",
        "Illustrateur",
        "Infirmière",
        "Ingénieur civil",
        "Ingénieur électronicien",
        "Ingénieur du BTP",
        "Inspecteur de l'action sanitaire et sociale",
        "Jardinier",
        "Jardinier paysagiste",
        "Journaliste",
        "Juge",
        "Kinésithérapeute",
        "Linguiste",
        "Machiniste",
        "Magasinier",
        "Maître d'hôtel",
        "Manipulateur radio",
        "Masseur",
        "Mécanicien aéronautique",
        "Médecin",
        "Moniteur d'auto-école",
        "Monteur électricien",
        "Nutritionniste",
        "Officier",
        "Opérateur de production",
        "Opérateur d'usinage sur commande numérique (UCN)",
        "Opticien",
        "Orthophoniste",
        "Personal Trainer",
        "Pharmacien",
        "Photographe",
        "Physicien",
        "Physicien médical",
        "Pilote",
        "Politicien",
        "Pompier",
        "Poseur de sol (solier)",
        "Prêtre",
        "Procureur",
        "Professeur des écoles",
        "Professeur d'éducation physique (EPS)",
        "Professeur de français langue étrangère",
        "Porfesseur des universités",
        "Psychologue",
        "Réceptionniste",
        "Réceptionniste d'hôtel",
        "Responsable communication",
        "Responsable grands comptes",
        "Responsable service clientèle",
        "Sages-femmes",
        "Secrétaire médicale",
        "Serveur",
        "Skipper",
        "Soldat",
        "Soudeur",
        "Statisticien",
        "Surveillant pénitentiaire",
        "Technicien alarme intrusion",
        "Technicien d'analyses biomédicales",
        "Technicien de maintenance informatique",
        "Technicien d'exploitation",
        "Téléconseiller",
        "Test manager",
        "Travailleur social",
        "Urbaniste",
        "Vendeur",
        "Vétérinaire",
        "Webmaster",
      ],
      cust: false,
      imageNps: "",
      profession: "",
      errorLogin: "",
      emailNew: "",
      name: "",
      success: false,
      deleted: false,
      signUpMod: false,
      signInMod: true,
      recorver: false,
      recSend: false,
      mobile: false,
      newl: false,
      errors: [],
    };
  },
  created() {

    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },
  computed: {
    authenticated() {
      return localStorage.getItem("landsapp");
    },
    userCurr() {
      console.log("this.$store.state.auth", this.$store.state.auth);
      return this.$store.state.auth.userInfo;
    },
  },
  watch: {
    $route(to) {
      console.log('to', to.fullPath);
      if (to.fullPath != "/") {
        document.body.classList.remove("leading-normal");
        console.log(" document.body.classList", document.body.classList)
      }
    },
    password() {
      this.has_minimum_lenth = this.password.length > 8;
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
    },
    passwordRep() {
      if (this.password === this.passwordRep) {
        this.repeted = true;
      } else {
        this.repeted = false;
      }
    },
    userCurr(val) {
      console.log("val", val);
      this.firstname = val.firstname;
      this.imageNps = val.image;
    },
  },
  methods: {
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    ...mapActions({
      whoami: "auth/whoami",
      getAllPost: "posts/getAll",
      register: "auth/register",
      login: "auth/login",
      rec: "auth/rec",
      newletter: "auth/newLetter",
    }),
    createNew() {
      if (localStorage.getItem("landsapp")) {
        this.$router.push({ name: "post" });
      } else {
        this.signinMet();
      }
    },
    langSelec(event) {
      console.log(event);
      this.$i18n.locale = event.value
    },
    accountSet() {
      console.log("il est dans la fonction")
      if (localStorage.getItem("landsapp")) {
        this.$router.push({ name: 'setting' });
      } else {
        this.signinMet();
      }
    },
    submit() {
      const data = {
        email: this.emailNew,
        name: this.name,
      };
      this.errors = [];
      schemaTwo
        .validate(data, { abortEarly: false })
        .then(async (valid) => {
          console.log(valid);
          if (valid) {
            await this.newletter(data).then(async (response) => {
              console.log(response);
              this.newl = true;
            });
          }
        })
        .catch((err) => {
          this.load = false;
          err.inner.forEach((e) => {
            console.log(e.message, e.path);
            this.errors.push(e.message);
          });
        });
    },

    openSet() {
      const All = document.getElementsByTagName("html")[0];
      var eventer = () => {
        console.log("il est appelé et je fais nimporte quoi");
        this.cust = false;
        All.removeEventListener("click", eventer);
      };
      if (this.cust) {
        this.cust = false;
        All.removeEventListener("click", eventer);
      } else {
        All.removeEventListener("click", eventer);
        this.cust = true;
        setTimeout(() => {
          All.addEventListener("click", eventer);
        }, 500);
      }
    },

    signOut() {
      localStorage.clear();
      location.reload();
    },

    async recover() {
      const data = {
        email: this.email,
      };
      await this.rec(data);
      this.recSend = true;
    },

    async registerMet() {
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
        username: this.username,
        password: this.password,
        email: this.email,
        phone: this.phone,
        profession: this.profession,
        role: 0,
      };

      await this.register(data);
      window.location.reload();
    },
    async loginMet() {
      const data = {
        username: this.username,
        password: this.password,
      };
      const res = await this.login(data);
      console.log(res, "ress");
      if (res.statusCode == 404) {
        this.errorLogin = "User not found";
        localStorage.setItem("lands.errorPass", true);
      } else if (res.statusCode == 403) {
        this.errorLogin = "Username or password failed";
        localStorage.setItem("lands.errorPass", true);
      } else {
        window.location.reload();
      }
    },
    bibouille(e) {
      console.log(e, "e");
      this.mobile = !this.mobile;
      /*setTimeout(() => {
        const famous = document.getElementsByClassName(
          "mmenu-init mm-menu mm-offcanvas mm-opened"
        );
        console.log("famous", famous[0]);
        famous[0].style.zIndex = 22222222;
      }, 300);*/
    },
    close() {
      this.mobile = !this.mobile;
    },
    signinMet() {
      this.signin = true;

      setTimeout(() => {
        const elPb = document.querySelectorAll(".mfp-ready");

        elPb.forEach((element) => {
          element.style.display = "none";
        });
      }, 1000);
    },
  },
  async mounted() {
    await this.getAllPost();
    if (localStorage.getItem("landsapp")) {
      await this.whoami();
    }
    if (localStorage.getItem("landsapp.errorPass")) {
      localStorage.removeItem("lands.errorPass");
      this.errorPass = true;
    }

    if (localStorage.getItem("lands.delete")) {
      localStorage.removeItem("lands.delete");
      this.deleted = true;

      setTimeout(() => {
        this.deleted = false;
      }, 15000);
    }

    if (localStorage.getItem("lands.newRegisterSucces")) {
      localStorage.removeItem("lands.newRegisterSucces");
      this.success = true;

      setTimeout(() => {
        this.success = false;
      }, 15000);
    }
    var scrollpos = window.scrollY;
    var header = document.getElementById("header");
    var navcontent = document.getElementById("nav-content");
    var navaction = document.getElementById("navAction");
    // var brandname = document.getElementById("brandname");
    var toToggle = document.querySelectorAll(".toggleColour");

    document.addEventListener("scroll", function () {
      /*Apply classes for slide in bar*/
      scrollpos = window.scrollY;

      if (scrollpos > 10) {
        header.classList.add("bg-white");
        navaction?.classList.remove("bg-white");
        navaction?.classList.add("gradient");
        navaction?.classList.remove("text-gray-800");
        navaction?.classList.add("text-white");
        //Use to switch toggleColour colours
        for (var i = 0; i < toToggle.length; i++) {
          toToggle[i].classList.add("text-gray-800");
          toToggle[i].classList.remove("text-white");
        }
        header.classList.add("shadow");
        navcontent.classList.remove("bg-gray-100");
        // navcontent.classList.add("bg-white");
      } else {
        header.classList.remove("bg-white");
        navaction?.classList?.remove("gradient");
        // navaction.classList.add("bg-white");
        navaction?.classList.remove("text-white");
        navaction?.classList.add("text-gray-800");
        //Use to switch toggleColour colours
        for (var u = 0; u < toToggle.length; u++) {
          toToggle[u].classList.add("text-white");
          toToggle[u].classList.remove("text-gray-800");
        }

        header.classList.remove("shadow");
        navcontent.classList.remove("bg-white");
        // navcontent.classList.add("bg-gray-100");
      }
    });
    var navMenuDiv = document.getElementById("nav-content");
    console.log(navMenuDiv, "------------------");
    var navMenu = document.getElementById("nav-toggle");

    document.onclick = check;
    function check(e) {
      var target = (e && e.target) || (event && event.srcElement);

      //Nav Menu
      if (!checkParent(target, navMenuDiv) && window.screen.width <= 1024) {
        // click NOT on the menu
        if (checkParent(target, navMenu)) {
          // click on the link
          if (navMenuDiv.classList.contains("hidden")) {
            navMenuDiv.classList.remove("hidden");
          } else {
            navMenuDiv.classList.add("hidden");
          }
        } else {
          // click both outside link and outside menu, hide menu
          navMenuDiv.classList.add("hidden");
        }
      }
    }
    function checkParent(t, elm) {
      while (t.parentNode) {
        if (t == elm) {
          return true;
        }
        t = t.parentNode;
      }
      return false;
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.active {
  border-bottom: 2px solid red !important;
  color: red;
}


#nav {
  padding: 30px;
}

@media screen and (max-width: 992px) {
  .flex-col-menu {
    margin-left: -15px;
    background-color: green;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }
}

#nav a {
  font-weight: bold;
  color: #cee5fc;
}

#nav a.router-link-exact-active {
  color: #389a6e;
}
</style>

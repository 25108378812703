//import api from "@/api"
import axios from "axios";

const actions = {
  async sigle({ commit }, data) {
    return await axios
      .post(process.env.VUE_APP_API_BASE_URL + "/uploads/multiple", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          responseType: "json",
        },
      })
      .then((response) => {
        if (response.status == 201) {
          commit;
          return response.data[0].filename;
        }
      });
  },
};

export default actions;

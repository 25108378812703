<template>
  <div class="container">
    <div class="row sticky-wrapper">
      <div v-if="post" class="col-lg-8 col-md-8 padding-right-30 text-start">
        <!-- Titlebar -->
        <div id="titlebar" class="listing-titlebar">
          <div class="listing-titlebar-title">
            <div class="flex">
              <h2>
                {{ post.title }} ({{
                  post.type === "SELL" ? "A vendre" : " A louer"
                }})

                <!--span class="listing-tag">o</span-->

                <meta property="og:type" content="article" />
                <meta property="og:title" :content="post.title" />
                <meta property="og:description" :content="post.title" />
                <meta property="og:url" :content="'www.landsapp.com/#/postDetails/' + id" />
                <meta property="og:site_name" content="LandsApp" />
              </h2>
            </div>

            <span>
              <a class="listing-address">
                {{ post.amount }}
                {{
                  post.amountCurrency && post.amountCurrency !== ""
                  ? post.amountCurrency
                  : "XOF"
                }}
              </a>
            </span>

            <span>
              <a class="listing-address ml-6">
                <i class="fa fa-map-marker"></i>
                {{ post.address }}
              </a>
            </span>
          </div>
        </div>

        <div class="grid grid-cols-3 pl-2 pr-10 mb-9 gap-4">
          <div class="">

            <inner-image-zoom width="190" :src="baseUrl + '/' + post.image" />
          </div>
          <div class="">
            <!--img :src="baseUrl + '/' + image" alt="" /-->
          </div>
          <!-- /*v-for="(image, index) in post.images.split(';')"
            :key="index"*/ -->
        </div>

        <!-- Listing Nav -->
        <div id="listing-nav" class="listing-nav-container">
          <ul class="listing-nav">
            <li>Description</li>
          </ul>
        </div>

        <!-- Overview -->
        <div id="listing-overview" class="listing-section">
          <!-- Description -->

          <div v-html="post.description"></div>

          <div v-if="justifies.lenght != 0">
            <a v-for="(just, index) in justifies" :key="index" :href="just.includes('http') ? just : baseUrl + '/' + just"
              target="_blank" rel="noopener noreferrer">{{ just }}</a>
          </div>
          <!-- Listing Contacts -->
          <div class="listing-links-container">
            <ul v-if="auth" class="listing-links contact-links">
              <li>
                <a :href="'tel:' + post.owner ? post.owner.phone : ''" class="listing-links"><i class="fa fa-phone"></i>
                  {{ post.owner ? post.owner.phone : "" }}</a>
              </li>
              <li>
                <a :href="'mailto:' + post.owner ? post.owner.email : ''" class="listing-links"><i
                    class="fa fa-envelope-o"></i>
                  {{ post.owner ? post.owner.email : "" }}</a>
              </li>
            </ul>
            <div class="clearfix"></div>

            <ul class="listing-links">
              <li>
                <a :href="
                  'http://www.facebook.com/sharer/sharer.php?u=www.landsapp.com/#/postDetails/' +
                  id
                " target="_blank" class="listing-links-fb"><i class="fa fa-facebook-square"></i> Facebook</a>
              </li>
              <li class="md:hidden">
                <a :href="
                  'whatsapp://send?text=' +
                  'www.landsapp.com/#/postDetails/' +
                  id
                " data-action="share/whatsapp/share" target="_blank" style="background: green"
                  class="listing-links-yt md:hidden"><i class="fa fa-whatsapp"></i> Whatsapp</a>
              </li>

              <li>
                <a :href="
                  'http://twitter.com/share?text=J\'ai repéré cette annonce pour toi&url=www.landsapp.com/#/postDetails/' +
                  id
                " target="_blank" class="listing-links-tt"><i class="fa fa-twitter"></i> Twitter</a>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>

          <!-- Amenities -->
        </div>

        <!-- Food Menu -->

        <div @click="deleteN" class="cursor-pointer mt-10 py-2 bg-red-800 text-white px-4 rounded-lg" v-if="isOwner"
          style="width: 100px">
          <svg v-if="load" class="animate-spin w-8 h-8 text-black z-50 mr-3 ..." viewBox="0 0 24 24">
            <circle xmlns="http://www.w3.org/2000/svg" cx="50" cy="50" fill="none" stroke="#ecfefd" stroke-width="10"
              stroke-height="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138"
              transform="matrix(1,0,0,1,0,0)" style="
                  transform: matrix(1, 0, 0, 1, 0, 0);
                  animation-play-state: paused;
                " />
          </svg>
          Supprimer
        </div>

        <!-- Food Menu / End -->

        <!-- Location -->
        <div id="listing-location" class="listing-section">
          <h3 class="listing-desc-headline margin-top-60 margin-bottom-30">
            Location
          </h3>

          <div id="singleListingMap-container">
            <l-map style="width: 500px; height: 350px" class="w-85 h-80" v-if="showMap" :zoom="zoom" :center="maps"
              :options="mapOptions" @update:center="centerUpdate" @update:zoom="zoomUpdate">
              <l-tile-layer :url="url" />
              <l-marker :lat-lng="maps"> </l-marker>
            </l-map>
          </div>
        </div>
      </div>

      <!-- Sidebar
		================================================== -->

      <!-- Sidebar / End -->
      <div class="widget col-md-4" style="margin-top: 80px">
        <h3> {{ $t("home.lastAnn") }} </h3>
        <ul class="widget-tabs">
          <!-- Post #1 -->
          <li v-for="(element, index) in allPosts" :key="index">
            <div class="widget-content cursor-pointer">
              <div class="widget-thumb">
                <a @click="oneClicked(element.id)"><img :src="baseUrl + '/' + element.image" alt="" /></a>
              </div>

              <div class="widget-text">
                <h5>
                  <a @click="oneClicked(element.id)">{{ element.title }}
                  </a>
                </h5>
                <span>{{ format(element.created_at) }}</span>
              </div>
              <div class="clearfix"></div>
            </div>
          </li>


        </ul>
      </div>



    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { latLng } from "leaflet";
import moment from "moment";
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

export default {
  name: "Example",
  components: { LMap, LTileLayer, LMarker, 'inner-image-zoom': InnerImageZoom },
  props: {
    id: { require: true },
  },
  data() {
    return {
      vfOptions: {
        autoplay: true
      },
      isOwner: false,
      load: false,
      geojson: {
        type: "FeatureCollection",
        features: [
          // ...
        ],
      },
      cUrl: window.location.href,
      geojsonOptions: {
        // Options that don't rely on Leaflet methods.
      },
      allPosts: [],
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      auth: localStorage.getItem("landsapp"),

    };
  },
  computed: {
    posts() {
      console.log("la valeur change");
      // const size = 4;
      return this.$store.state.posts.all;
    },
    post() {
      return this.$store.state.posts.current;
    },
    justifies() {
      return this.$store.state.posts.current.justifies != "" ? this.$store.state.posts.current.justifies.split("#") : []
    },
    maps() {
      const datas = this.$store.state.posts.current;
      const wil = latLng(
        typeof datas.latitude === "string"
          ? parseFloat(datas.latitude)
          : datas.latitude,
        typeof datas.longitude === "string"
          ? parseFloat(datas.longitude)
          : datas.longitude
      );

      return wil;
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.getAllPost();

    setTimeout(() => {
      console.log('icicicci')
      console.dir(this.post);
      this.isOwner = this.post && this.post.owner
        ? this.post.owner.id == this.$store.state.auth.userId
        : false;
    }, 1000);
    //this.isOwner = this.post.owner.id == this.$store.state.auth.userId;
  },
  async beforeMount() {
    const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");

    // And now the Leaflet circleMarker function can be used by the options:
    this.geojsonOptions.pointToLayer = (feature, latLng) =>
      circleMarker(latLng, { radius: 8 });
    this.mapIsReady = true;
    console.log(
      "this.$store.state.posts.current",
      this.$store.state.posts.current
    );
    if (this.id) {
      console.log("il l'a");
      this.getCurrentPost(this.id);
    } else {
      console.log("il l'a pas");
      this.$router.push({ name: "Home" });
    }
    console.log(
      "this.$store.state.posts.current",
      this.$store.state.posts.current
    );
  },
  watch: {
    posts: function (val) {
      this.allPosts = val.reverse();
      console.log(val, "la valeur change");
      this.allPosts = this.allPosts.slice(0, 3);
    },
  },
  methods: {
    ...mapActions({
      getAllPost: "posts/getAll",
      whoami: "auth/whoami",
      delete: "posts/delete",
      getCurrentPost: "posts/getOne",
    }),
    oneClicked(id) {
      this.$router.push({ name: "postDetails", params: { id } });
    },
    format(value) {
      if (value) {
        return moment(String(value)).format("DD MMMM YYYY hh:mm");
      }
    },
    deleteN() {
      this.load = true;
      console.log("il est bien ici");
      this.delete(this.post.id);
    },
  },
};
</script>

<style scoped>
#slideshow {
  position: relative;
  width: 240px;
  height: 250px;
  padding: 15px;
  margin: 0 auto 2em;
  border: 1px solid #ddd;
  background: #fff;
  /* CSS3 effects */
  background: linear-gradient(#fff, #fff 20%, #eee 80%, #ddd);
  border-radius: 2px 2px 2px 2px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

/* avanced box-shadow
 * tutorial @
 * https://www.creativejuiz.fr/blog/les-tutoriels/ombre-avancees-avec-css3-et-box-shadow 
*/
#slideshow:before,
#slideshow:after {
  position: absolute;
  bottom: 16px;
  z-index: -10;
  width: 30%;
  height: 20px;
  content: " ";
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4), 0 20px 10px rgba(0, 0, 0, 0.7);
}

#slideshow:before {
  left: 0;
  transform: rotate(-4deg);
}

#slideshow:after {
  right: 0;
  transform: rotate(4deg);
}

/* gestion des dimensions et débordement du conteneur */
#slideshow .container {
  position: relative;
  width: 220px;
  height: 240px;
  overflow: hidden;
}

/* on prévoit un petit espace gris pour la timeline */
#slideshow .container:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: " ";
  width: 100%;
  height: 1px;
  background: #999;
}

/* 
   le conteneur des slides
   en largeur il fait 100% x le nombre de slides
*/
#slideshow .slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 400%;
  height: 240px;
}

/* annulation des marges sur figure */
#slideshow figure {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}

/* petit effet de vignette sur les images */
#slideshow figure:after {
  position: absolute;
  display: block;
  content: " ";
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.5) inset;
}

/* styles de nos légendes */
#slideshow figcaption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  padding: 20px;
  margin: 0;
  border-top: 1px solid rgb(225, 225, 225);
  text-align: center;
  letter-spacing: 0.05em;
  word-spacing: 0.05em;
  font-family: Georgia, Times, serif;
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  color: #555;
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.3);
}

/* fonction d'animation, n'oublies pas de prefixer ! */
@keyframes slider {

  0%,
  20%,
  100% {
    left: 0;
  }

  25%,
  45% {
    left: -120%;
  }

  50%,
  70% {
    left: -230%;
  }
}

#slideshow .slider {
  /* ... avec la propriété animation */
  animation: slider 32s infinite;
}

#timeline {
  position: absolute;
  background: #999;
  bottom: 15px;
  left: 15px;
  height: 1px;
  background: rgb(214, 98, 13);
  background: rgba(214, 98, 13, 0.8);
  width: 0;
  /* fonction d'animation */
  animation: timeliner 32s infinite;
}

@keyframes timeliner {

  0%,
  25%,
  50%,
  75%,
  100% {
    width: 0;
  }

  20%,
  45%,
  70%,
  90% {
    width: 640px;
  }
}

@keyframes figcaptionner {

  0%,
  25%,
  50%,
  75%,
  100% {
    bottom: -55px;
  }

  5%,
  20%,
  30%,
  45%,
  55%,
  70%,
  80%,
  95% {
    bottom: 5px;
  }
}

#slideshow figcaption {
  /* ... la propriété animation */
  animation: figcaptionner 32s infinite;
}

.play_commands {
  /* positionnement en haut à droite */
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 10;
  /* dimensionnement des icônes */
  width: 22px;
  height: 22px;
  text-indent: -9999px;
  border: 0 none;
  /* placez l'opacité à 1 si vous souhaitez voir les commandes */
  opacity: 0;
  /* préparation de transition sur opacicty et right */
  transition: opacity 1s, right 1s;
}

/* on décale play légèrement sur la gauche */
.play {
  right: 55px;
  cursor: default;
}

/* création de l'icône pause avec 2 pseudos éléments */
.pause:after,
.pause:before {
  position: absolute;
  display: block;
  content: " ";
  top: 0;
  width: 38%;
  height: 22px;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.pause:after {
  right: 0;
}

.pause:before {
  left: 0;
}

/* création de l'icône play avec des bordures */
.play {
  width: 1px;
  height: 1px;
  /* les transparentes forment la flèche */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 20px solid #fff;
  border-left: 20px solid rgba(255, 255, 255, 0.5);
  /* renseignez 1 pour voir l'icône de suite */
  opacity: 0;
}

/* apparition du bouton pause au survole */
/* apparition des boutons au focus */
#slideshow:hover .pause,
.play_commands:focus {
  opacity: 1;
  outline: none;
}

/* stopper les animation */
.sl_command:target~#slideshow .slider,
.sl_command:target~#slideshow figcaption,
.sl_command:target~#slideshow #timeline,
.sl_command:target~#slideshow .dots_commands li:first-child a:after {
  animation-play-state: paused;
}

/* redémarrer les animations */
#sl_play:target~#slideshow .slider,
#sl_play:target~#slideshow figcaption,
#sl_play:target~#slideshow #timeline,
#sl_play:target~#slideshow .dots_commands li:first-child a:after {
  animation-play-state: running;
}

/* switch entre les boutons */
.sl_command:target~#slideshow .pause {
  opacity: 0;
}

#sl_play:target~#slideshow:hover .pause,
#sl_play:target~#slideshow .pause:focus {
  opacity: 1;
}

.sl_command:target~#slideshow .play {
  opacity: 1;
  right: 25px;
  cursor: pointer;
}

#sl_play:target~#slideshow .play {
  opacity: 0;
  right: 55px;
  cursor: default;
}
</style>

//import store from '@/store'

/*
 * If the user is not authenticated and visits
 * a page that requires authentication, redirect to the login page
 */
export default async (to, from, next) => {
  //const  userMail = store.getters['auth/authenticated'] ? store.getters['auth/userInfo'].email : ''
  const authenticated = localStorage.getItem("landsapp");

  //console.log(passwordResetOnce,'passwordResetOncepasswordResetOnce')

  if (!authenticated && to.matched.some((route) => route.meta.auth)) {
    return next({ name: "SignIn", query: { redirect: to.fullPath } });
  }

  next();
};

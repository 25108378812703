import api from "@/api";

const actions = {
  async create({ commit }, data) {
    await api["lands"].create(data).then((response) => {
      if (response.isSuccessful) {
        commit("CURRENT_POST", response.data);
        //TODO: working on this
        localStorage.setItem("land.postId", response.data.id);
        return response.data.id;
      }
    });
  },

  async getAll({ commit }) {
    return await api["lands"].all().then((response) => {
      if (response.isSuccessful) {
        //commit("POST_ALL", Array.prototype.reverse.call(response.data));
        commit("POST_ALL", response.data);
        return response.data;
      }
    });
  },

  async getSearch({ commit }, data) {
    return await api[`lands/search?text=${data}`].all().then((response) => {
      if (response.isSuccessful) {
        //commit("POST_ALL", Array.prototype.reverse.call(response.data));
        commit("POST_ALL", response.data);
        return response.data;
      }
    });
  },

  async delete({ commit }, data) {
    return await api[`lands`].delete(data).then((response) => {
      if (response.isSuccessful) {
        //commit("POST_ALL", Array.prototype.reverse.call(response.data));
        commit("", response.data);
        localStorage.setItem("lands.delete", true);
        location.href = "/";
        return response.data;
      }
    });
  },

  async getOne({ commit }, id) {
    console.log(id, "id");
    return await api[`lands/${id}`].all().then((response) => {
      if (response.isSuccessful) {
        commit("CURRENT_POST", response.data);
        return response.data;
      }
    });
  },
  async whoami({ commit }) {
    await api["auth/whoami"].all().then((response) => {
      if (response.isSuccessful) {
        commit("USER_INFO", response.data);
      }
    });
  },
};

export default actions;

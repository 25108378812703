import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import store from './store'
import router from './router'
import "./registerServiceWorker";
import i18n from './i18n'
import { VueTwTypeahead } from 'vue-tailwindcss-typeahead';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Dropdown from 'vue-simple-search-dropdown';

import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import InnerImageZoom from 'vue-inner-image-zoom'


createApp(App).use(i18n).component('QuillEditor', QuillEditor).use(VueTwTypeahead).use(InnerImageZoom).use(Dropdown).use(router).use(store).mount('#app')
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
}

self.addEventListener('install', (event) => {
    // Installation du service worker
    event.waitUntil(
      caches.open('my-cache').then((cache) => {
        return cache.addAll([
          // Liste des fichiers à mettre en cache lors de l'installation initiale
        ]);
      })
    );
  });
  
  self.addEventListener('fetch', (event) => {
    event.respondWith(
      caches.open('my-cache').then((cache) => {
        return cache.match(event.request).then((response) => {
          const fetchPromise = fetch(event.request).then((networkResponse) => {
            // Mettre à jour le cache avec la nouvelle version
            const cacheClone = cache.clone();
            cacheClone.put(event.request, networkResponse);
            return networkResponse;
          });
  
          return response || fetchPromise;
        });
      })
    );
  });
  
  self.addEventListener('activate', (event) => {
    // Activation du service worker
    event.waitUntil(
      caches.keys().then((cacheNames) => {
        return Promise.all(
          cacheNames.map((cacheName) => {
            if (cacheName !== 'my-cache') {
              // Supprimer les caches autres que celui en cours d'utilisation
              return caches.delete(cacheName);
            }
          })
        );
      })
    );
  });
  
  // Génération d'un identifiant unique pour forcer la mise à jour du cache
  function generateCacheBustingString() {
    return Math.random().toString(36).substring(2, 15);
  }
  
  self.addEventListener('message', (event) => {
    if (event.data.action === 'skipWaiting') {
      // Envoyer un message au service worker pour qu'il saute la phase d'attente
      self.skipWaiting();
  
      // Générer un nouvel identifiant unique pour forcer la mise à jour du cache
      const cacheBustingString = generateCacheBustingString();
  
      // Envoyer l'identifiant unique à l'application
      self.clients.matchAll().then((clients) => {
        clients.forEach((client) => {
          client.postMessage({
            action: 'updateCache',
            cacheBustingString: cacheBustingString,
          });
        });
      });
    }
  });
  
